import { Loading } from '@/components';
import { Flex, Heading, Text } from '@grupoboticario/flora-react';

const LoadingTableData = (): JSX.Element => (
  <Flex data-testid="table-loading" direction="column" align="center" justify="center" css={{ marginTop: '$20' }}>
    <Loading size="lg" thickness="4px" />

    <Heading level="6" css={{ marginTop: '$5', color: '$dark-light' }}>
      Carregando as informações
    </Heading>

    <Text size="bodyMediumShort" css={{ marginTop: '$4', color: '$backgroundAltSecondary' }}>
      Por favor, aguarde um instante.
    </Text>
  </Flex>
);

export { LoadingTableData };

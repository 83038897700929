import { Flex, Radio, RadioGroup, Text } from '@grupoboticario/flora-react';
import { Controller, useFormContext } from 'react-hook-form';

interface ConfigGapSize {
  row?: string;
  column?: string;
}
export interface ControlledRadioButtonData {
  id: string;
  label: string;
  value: string | number;
}

interface ControlledRadioGroupProps {
  registerName: string;
  defaultValue?: string;
  direction?: 'column' | 'row';
  disabled?: boolean;
  options: ControlledRadioButtonData[];
  gapSize?: ConfigGapSize;
  required?: boolean;
}

const defaultGapSize = {
  row: '$4',
  column: '$2',
};

const ControlledRadioGroup = ({
  registerName,
  defaultValue,
  direction = 'column',
  disabled = false,
  gapSize = defaultGapSize,
  options = [],
  required = false,
}: ControlledRadioGroupProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      name={registerName}
      defaultValue={defaultValue}
      control={control}
      rules={{ required }}
      render={({ field, fieldState }) => (
        <RadioGroup value={field.value} onValueChange={field.onChange}>
          <Flex direction={direction} gap={gapSize[direction]}>
            {options.map(option => (
              <Flex align="center" key={option.id}>
                <Radio
                  id={option.id}
                  value={option.value}
                  disabled={disabled}
                  css={{
                    color: fieldState.error ? '$error-alt' : 'unset',
                  }}
                />
                <Text as="label" htmlFor={option.id} css={{ marginLeft: '$2' }}>
                  {option.label}
                </Text>
              </Flex>
            ))}
          </Flex>
        </RadioGroup>
      )}
    />
  );
};

export { ControlledRadioGroup };

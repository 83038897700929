import { Button, Tooltip } from '@grupoboticario/flora-react';

interface TooltipButtonProps {
  label: string;
  onClick: () => void;
  tipText: string;
}

export const TooltipButton = ({ label, onClick, tipText }: TooltipButtonProps) => (
  <Tooltip align="center" direction="bottom" text={tipText}>
    <Button
      data-testid="tooltip-button__btn"
      size="small"
      hierarchy="secondary"
      css={{ height: '$4', padding: '$1' }}
      onClick={onClick}
    >
      {label}
    </Button>
  </Tooltip>
);

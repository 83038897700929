import { useEffect } from 'react';
import { Box, Button, Flex } from '@grupoboticario/flora-react';
import { PlusIcon } from '@grupoboticario/flora-react-icons';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DeliveryFormData } from '@/types';
import { SpecialConditionsItem } from '@/components/delivery-form';

export const SpecialConditions = (): JSX.Element => {
  const { control, watch } = useFormContext<DeliveryFormData>();
  const hasDeliveryValue = watch('hasDelivery');

  const { fields, append, remove } = useFieldArray({
    name: 'specialConditions',
    control,
  });

  useEffect(() => {
    if (!fields.length) {
      append({ hasFreeDelivery: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="special-conditions-list">
      <Flex direction="column" gap="$5">
        {fields.map((field, idx) => (
          <SpecialConditionsItem
            key={field.id}
            index={idx}
            disabled={!hasDeliveryValue}
            onRemove={() => remove(idx)}
            hasRemoveButton={fields.length > 1}
            {...field}
          />
        ))}
      </Flex>
      <Button
        as="button"
        size="small"
        hierarchy="tertiary"
        css={{ marginTop: '$4' }}
        disabled={!hasDeliveryValue}
        type="button"
        onClick={() => {
          append({ hasFreeDelivery: false });
        }}
        icon={<PlusIcon size="16" />}
        has="iconLeft"
        isFull={false}
      >
        Adicionar condição
      </Button>
    </Box>
  );
};

import { Flex, Heading, Text } from '@grupoboticario/flora-react';

const TableEmptySate = (): JSX.Element => (
  <Flex data-testid="table-empty-state" direction="column" align="center" justify="center" css={{ marginTop: '$20' }}>
    <Heading level="6" css={{ marginTop: '$5', color: '$dark-light' }}>
      Nenhuma informação correspondeu à sua busca
    </Heading>

    <Text size="bodyLargeShortRegular" css={{ marginTop: '$4', color: '$nonInteractiveAuxiliar' }}>
      Por favor, tente realizar uma nova busca.
    </Text>
  </Flex>
);

export { TableEmptySate };

import React, { useEffect, useMemo } from 'react';
import { BusinessHoursFormData } from '@/types';
import { Box, Button, Flex, Grid } from '@grupoboticario/flora-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ClockIcon, CrossIcon } from '@grupoboticario/flora-react-icons';
import { FormInput } from '@/components/shared';

interface OpenHoursListProps {
  parentRegisterName: keyof Pick<BusinessHoursFormData, 'days' | 'holidays'>;
  index?: number;
}

type ParentRegisterNameType = 'days' | `days.${number}.hours` | 'holidays.hours';

const OpenHoursList = ({ parentRegisterName, index = 0 }: OpenHoursListProps): JSX.Element => {
  const registerName = useMemo(() => {
    if (parentRegisterName === 'days') {
      return `days.${index}.hours`;
    }
    return `holidays.hours`;
  }, [index, parentRegisterName]) as ParentRegisterNameType;

  const { control, trigger } = useFormContext<BusinessHoursFormData>();
  const { fields, append, remove } = useFieldArray({
    name: registerName,
    control,
  });

  useEffect(() => {
    if (!fields.length && parentRegisterName === 'holidays') {
      append({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [append]);

  return (
    <Box data-testid="open-hours-list">
      <Grid templateColumns="1fr 1fr $12" gapX="$4" gapY="$2">
        {fields.map((field, idx) => (
          <React.Fragment key={field.id}>
            <Box>
              <FormInput
                id="opensAt"
                data-testid="opensAt"
                labelText="Horário de abertura:"
                optionalText="*"
                registerName={`${registerName}.${idx}.opensAt`}
                placeholder="09:00"
                mask="xx:xx"
                onBlur={() => trigger(`${registerName}.${idx}.closesAt` as ParentRegisterNameType)}
              />
            </Box>
            <Box>
              <FormInput
                id="closesAt"
                data-testid="closesAt"
                labelText="Horário de fechamento:"
                optionalText="*"
                registerName={`${registerName}.${idx}.closesAt`}
                placeholder="13:00"
                mask="xx:xx"
                onBlur={() => trigger(`${registerName}.${idx}.opensAt` as ParentRegisterNameType)}
              />
            </Box>
            <Flex align="center" justify="right" css={{ height: '$12' }}>
              {idx !== 0 && (
                <Button
                  data-testid="delete-time-interval"
                  hierarchy="tertiary"
                  has="iconOnly"
                  icon={<CrossIcon size="32" />}
                  aria-label="Excluir intervalo de horários"
                  onClick={() => remove(idx)}
                />
              )}
            </Flex>
          </React.Fragment>
        ))}
      </Grid>
      <Button
        as="button"
        data-testid="add-time-interval"
        type="button"
        size="small"
        hierarchy="tertiary"
        css={{ marginTop: '$4' }}
        onClick={() => {
          append({});
        }}
        has="iconLeft"
        icon={<ClockIcon size="16" />}
        isFull={false}
      >
        Adicionar horários
      </Button>
    </Box>
  );
};

export { OpenHoursList };

import { Button } from '@grupoboticario/flora-react';
import { PencilIcon } from '@grupoboticario/flora-react-icons';
import { useNavigate } from 'react-router-dom';

interface TableActionsProps {
  id: string;
}
const TableActions = ({ id }: TableActionsProps): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Button
      hierarchy="tertiary"
      has="iconOnly"
      icon={<PencilIcon size="18" color="$9" />}
      aria-label="Buscar IR"
      onClick={() => navigate(`editar/${id}`)}
    />
  );
};

export { TableActions };

import { CSS, Flex, Text } from '@grupoboticario/flora-react';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface FormCheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  css?: CSS;
  registerName: string;
  label: string;
}

const FormCheckbox = ({ css, label, registerName, ...rest }: FormCheckboxProps): JSX.Element => {
  const { register, unregister } = useFormContext();

  useEffect(() => {
    return () => {
      unregister(registerName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex align="center" gap="$2" css={{ ...css }}>
      <input type="checkbox" {...rest} {...register(registerName, { disabled: rest.disabled })} />
      <Text
        as="label"
        htmlFor={rest.id}
        size="bodyMediumShort"
        css={{
          color: rest.disabled ? '$nonInteractiveAuxiliar' : '$nonInteractivePredominant',
          fontWeight: '$medium',
        }}
      >
        {label}
      </Text>
    </Flex>
  );
};

export { FormCheckbox };

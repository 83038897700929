import { Grid, Flex, Container, Heading, Box, ScrollArea, Button } from '@grupoboticario/flora-react';
import { PlusIcon } from '@grupoboticario/flora-react-icons';
import { useNavigate } from 'react-router-dom';
import { DrawerInfo, ServiceCenterTable } from './components';

export const ServiceCenter = () => {
  const navigate = useNavigate();

  return (
    <Grid css={{ height: '100%' }} templateRows="6rem auto">
      <Flex
        align="center"
        css={{ height: '100%', width: '100%', backgroundColor: '$statusAlertNonInteractiveAltPredominant' }}
      >
        <Container size="large">
          <Flex justify="space-between">
            <Heading level="6" css={{ fontWeight: '$medium' }}>
              Centrais de Serviço
            </Heading>
            <Button icon={<PlusIcon />} has="iconLeft" css={{ padding: '$4 $7' }} onClick={() => navigate('criar')}>
              Adicionar Central de Serviço
            </Button>
          </Flex>
        </Container>
      </Flex>
      <DrawerInfo />
      <Box css={{ overflow: 'hidden' }}>
        <ScrollArea css={{ height: '100%', width: '100%' }} orientation="vertical">
          <Container size="large">
            <Flex css={{ padding: '$9 0' }}>
              <ServiceCenterTable />
            </Flex>
          </Container>
        </ScrollArea>
      </Box>
    </Grid>
  );
};

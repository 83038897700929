import React from 'react';
import { Box, Container, Flex, Grid, Heading, ScrollArea } from '@grupoboticario/flora-react';

interface ServiceCenterFormWrapperProps {
  title: string;
}

const ServiceCenterFormWrapper = ({ title, children }: React.PropsWithChildren<ServiceCenterFormWrapperProps>) => (
  <Grid css={{ height: '100%' }} templateRows="6rem auto">
    <Flex
      align="center"
      css={{ height: '100%', width: '100%', backgroundColor: '$statusAlertNonInteractiveAltPredominant' }}
    >
      <Container size="large">
        <Heading level="6" css={{ fontWeight: '$medium' }}>
          {title}
        </Heading>
      </Container>
    </Flex>

    <Box css={{ overflow: 'hidden' }}>
      <ScrollArea css={{ height: '100%', width: '100%' }} orientation="vertical">
        <Container size="large">{children}</Container>
      </ScrollArea>
    </Box>
  </Grid>
);

export { ServiceCenterFormWrapper };

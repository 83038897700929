import { FormInput } from '@/components';
import { RegistrationFormData, defaultPhoneValues } from '@/types';
import { Box, Button, Flex, Grid, Text } from '@grupoboticario/flora-react';
import { PlusIcon, TrashIcon } from '@grupoboticario/flora-react-icons';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

interface InputPhoneProps {
  isDisabled: boolean;
}

const generateNewPhone = () => ({
  ...defaultPhoneValues,
  key: uuidv4(),
});

const InputPhone = ({ isDisabled }: InputPhoneProps) => {
  const { control, getValues, setValue, register } = useFormContext<RegistrationFormData>();
  const { append, fields, remove } = useFieldArray({
    name: 'phones',
    control,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append(generateNewPhone());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const primaryPhoneValue = getValues('isPrimaryPhone');

    const hasSelectedAsPrimaryPhone = fields.some(phone => phone.key === primaryPhoneValue);

    if (!hasSelectedAsPrimaryPhone) {
      setValue('isPrimaryPhone', fields?.[0]?.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {fields.map((field, index) => {
        const fieldValue = field.key;

        return (
          <Grid
            data-testid="input-phone-entry"
            key={field.id}
            templateColumns="repeat(12, 1fr)"
            gapX="$4"
            css={{ padding: '$2 0' }}
          >
            <Box css={{ gridColumn: 'span 6' }}>
              <FormInput
                registerName={`phones.${index}.number`}
                data-testid="input-phone"
                id="ponto-phone"
                optionalText="*"
                placeholder="(__) ____-____"
                mask="(xx) xxxxx-xxxx"
                labelText="Telefone:"
                disabled={isDisabled}
              />
            </Box>

            <Flex align="center" css={{ height: '48px', gridColumn: 'span 6' }} gap="$2">
              <input
                {...register('isPrimaryPhone')}
                data-testid="input-is-primary"
                type="radio"
                id={`primary-${index}`}
                value={fieldValue}
                disabled={isDisabled}
              />

              <Text size="bodyMediumStandard" as="label" htmlFor={`primary-${index}`}>
                Preferencial
              </Text>

              <input
                type="checkbox"
                data-testid="input-has-whatsApp"
                id={`hasWhatsApp-${index}`}
                value="true"
                {...register(`phones.${index}.hasWhatsApp`, {
                  setValueAs: value => value === 'true',
                })}
                disabled={isDisabled}
              />
              <Text size="bodyMediumStandard" as="label" htmlFor={`hasWhatsApp-${index}`}>
                Utiliza WhatsApp
              </Text>

              {index !== 0 && (
                <Button
                  data-testid="remove-phone"
                  css={{ marginLeft: 'auto' }}
                  icon={<TrashIcon />}
                  has="iconLeft"
                  hierarchy="tertiary"
                  disabled={isDisabled}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Excluir
                </Button>
              )}
            </Flex>
          </Grid>
        );
      })}
      <Box
        css={{
          gridColumn: 'span 12',
          paddingBottom: '$4',
        }}
      >
        <Button
          data-testid="add-phone"
          type="button"
          css={{ marginLeft: 'auto' }}
          icon={<PlusIcon />}
          has="iconLeft"
          hierarchy="tertiary"
          disabled={isDisabled}
          onClick={() => append(generateNewPhone())}
        >
          Adicionar telefone
        </Button>
      </Box>
    </>
  );
};

export { InputPhone };

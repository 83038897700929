import { Box, Button, Flex, Grid } from '@grupoboticario/flora-react';
import { useFormContext } from 'react-hook-form';
import { CrossIcon } from '@grupoboticario/flora-react-icons';
import { DeliveryFormData } from '@/types';
import { FormCheckbox, FormInputCurrency } from '@/components/shared';

interface SpecialConditionsItemProps {
  index: number;
  disabled: boolean;
  hasRemoveButton: boolean;
  onRemove: () => void;
}

export const SpecialConditionsItem = ({
  index,
  disabled,
  hasRemoveButton,
  onRemove,
}: SpecialConditionsItemProps): JSX.Element => {
  const { watch } = useFormContext<DeliveryFormData>();

  const hasFreeDeliveryValue = watch(`specialConditions.${index}.hasFreeDelivery`);

  return (
    <Flex direction="column">
      <Grid templateColumns="1fr 1fr $12 $12" gap="$4">
        <Box>
          <FormInputCurrency
            registerName={`specialConditions.${index}.deliveryCost`}
            id={`valor-frete-${index}`}
            labelText="Valor do frete:"
            data-testid="valor-frete-testid"
            disabled={disabled || hasFreeDeliveryValue}
          />
        </Box>
        <Box>
          <FormInputCurrency
            registerName={`specialConditions.${index}.minimumPurchaseValue`}
            id={`compra-minima-${index}`}
            labelText="A partir de:"
            data-testid="compra-minima-testid"
            disabled={disabled}
          />
        </Box>
        {hasRemoveButton && (
          <Flex align="center" justify="right" css={{ height: '$12' }}>
            <Button
              data-testid="service-center-filter-clear-btn"
              hierarchy="tertiary"
              has="iconOnly"
              aria-label="Excluir condição"
              onClick={onRemove}
              disabled={disabled}
              icon={<CrossIcon size="32" />}
            />
          </Flex>
        )}
      </Grid>
      <Box css={{ marginTop: '$3' }}>
        <FormCheckbox
          id={`frete-gratuito-${index}`}
          label="Frete gratuito"
          disabled={disabled}
          registerName={`specialConditions.${index}.hasFreeDelivery`}
        />
      </Box>
    </Flex>
  );
};

import { Flex, Text } from '@grupoboticario/flora-react';
import { FormCheckbox, FormFieldset, InputSwitch, InputNote } from '@/components/';
import { InstallmentOptions, PaymentHeader } from '@/components/payment-methods-form/';
import { useFormContext } from 'react-hook-form';
import { CheckboxDataType } from '@/components/pick-up-store-form/constants/checkboxValues';

const checkboxes: CheckboxDataType[] = [
  {
    label: 'A vista',
    id: 'pagamento-a-vista',
    value: 'bankSlipPayment.hasPaymentCash',
  },
  {
    label: '30 dias',
    id: 'pagamento-30-dias',
    value: 'bankSlipPayment.hasThirtyDaysPayment',
  },
];

const BankSlipPayment = () => {
  const { getFieldState, formState } = useFormContext();
  const fieldState = getFieldState('bankSlipPayment', formState);

  return (
    <Flex direction="column">
      <PaymentHeader />
      <Flex direction="column" gap="$4">
        {checkboxes.map(cb => (
          <FormCheckbox key={cb.id} label={cb.label} registerName={`${cb.value}`} id={cb.id} data-testid={cb.id} />
        ))}
        {fieldState.invalid && (
          <Text size="exceptionsAuxiliarRegular" weight="heavy" color="$1">
            {fieldState.error?.message}
          </Text>
        )}
      </Flex>

      <FormFieldset>
        <InputSwitch
          label="Consome o crédito disponível?"
          registerName="bankSlipPayment.consumesAvailableCredit"
          onText="Sim"
          offText="Não"
        />
      </FormFieldset>

      <InstallmentOptions
        parentRegisterName="bankSlipPayment"
        acceptInstallmentsLabel="Outras opções de parcelamento?"
      />

      <FormFieldset>
        <InputNote registerName="bankSlipPayment.note" />
      </FormFieldset>
    </Flex>
  );
};

export { BankSlipPayment };

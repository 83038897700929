import React from 'react';
import { Box, CSS, Flex, Text } from '@grupoboticario/flora-react';
import { TableSort } from '@/components/table/components';

interface TableHeaderCellProps {
  css?: CSS;
  children: React.ReactNode;
  isSortable?: boolean;
  field: string;
}

const TableHeaderCell = ({ css, children, isSortable, field }: TableHeaderCellProps): JSX.Element => (
  <Box data-testid={`table-header-cell-${field}`} as="th" css={{ height: '$10', ...css }}>
    <Flex align="center" css={{ height: '100%', width: '100%', paddingLeft: '$4' }}>
      <Text size="exceptionsRestrictedMedium" css={{ color: '$nonInteractiveAuxiliar', textTransform: 'uppercase' }}>
        {children}
      </Text>
      {isSortable && <TableSort field={field} />}
    </Flex>
  </Box>
);

export { TableHeaderCell };

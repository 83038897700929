import { ControlledSwitch } from '@/components';
import { CSS, Flex, SwitchProps, Text } from '@grupoboticario/flora-react';

interface InputSwitchProps extends Omit<SwitchProps, 'defaultValue'> {
  label: string;
  registerName: string;
  onText: string;
  offText: string;
  css?: CSS;
}
const InputSwitch = ({ label, registerName, onText, offText, css, ...rest }: InputSwitchProps): JSX.Element => (
  <Flex align="center" justify="space-between" css={{ ...css }}>
    <Text css={{ marginRight: 'auto', fontWeight: '$medium' }} size="bodyLargeShortRegular">
      {label}
    </Text>
    <ControlledSwitch registerName={registerName} onText={onText} offText={offText} {...rest} />
  </Flex>
);

export { InputSwitch };

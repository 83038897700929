import { events } from '@/api';
import { TooltipButton, PhonesList } from '@/components';
import { ServiceCenterPhones } from '@/types';
import { formatPhoneNumber } from '@/utils';
import { Flex, Text } from '@grupoboticario/flora-react';

interface PhoneTooltipCellProps {
  phones: ServiceCenterPhones[];
}

const PhoneTooltipCell = ({ phones = [] }: PhoneTooltipCellProps) => {
  const getSecondaryPhones = phones.filter(phone => !phone.primary);
  const tooltipPhones = phones.map(phone => ({
    phoneNumber: formatPhoneNumber(phone.phone) || '',
    hasWhatsAppActive: phone.whatsapp,
    isMainNumber: phone.primary,
  }));

  return (
    <Flex justify="space-between" align="center" css={{ padding: '$4' }}>
      <Text size="bodyMediumStandard">{formatPhoneNumber(phones[0]?.phone)}</Text>
      {!!getSecondaryPhones.length && (
        <TooltipButton
          label={`+${getSecondaryPhones.length}`}
          tipText="Ver outros números"
          onClick={() => {
            events.emit('OPEN_DRAWER_INFO', {
              title: 'Contatos',
              content: <PhonesList phones={tooltipPhones} />,
            });
          }}
        />
      )}
    </Flex>
  );
};

export { PhoneTooltipCell };

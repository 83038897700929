import { Button } from '@grupoboticario/flora-react';
import { CrossIcon } from '@grupoboticario/flora-react-icons';

interface ClearSearchIconButtonProps {
  onClick: () => void;
}

const ClearSearchIconButton = ({ onClick }: ClearSearchIconButtonProps): JSX.Element => (
  <Button
    data-testid="service-center-filter-clear-btn"
    hierarchy="tertiary"
    has="iconOnly"
    icon={<CrossIcon />}
    aria-label="Limpar filtro de CS"
    onClick={onClick}
  />
);

export { ClearSearchIconButton };

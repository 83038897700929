import { CSS, Grid, Text } from '@grupoboticario/flora-react';
import { ReactNode } from 'react';

interface FormWrapperProps {
  children: ReactNode;
  css?: CSS;
  title?: string;
  gap?: CSS['gap'];
}

const FormWrapper = ({ children, title, css, gap = '$4' }: FormWrapperProps) => (
  <Grid
    templateColumns="repeat(12, 1fr)"
    gap={gap}
    css={{
      background: '$statusAlertNonInteractiveAltPredominant',
      borderRadius: '$small',
      marginBottom: '$6',
      padding: '$6 $8',
      width: '100%',
      ...css,
    }}
  >
    {title?.length && (
      <Text
        size="auxiliarMini"
        css={{
          color: '$nonInteractiveAuxiliar',
          gridColumn: 'span 12',
          paddingBottom: '$4',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Text>
    )}

    {children}
  </Grid>
);

export { FormWrapper };
